import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import Nav from '../Nav/Nav';
// import { Helmet } from 'react-helmet';
import Scroll from "../LocomotiveScroll/LocomotiveScroll";
// import ScrollSmoother from "../ScrollSmoother";
import PopupBlock from "../PopupBlock/PopupBlock";
import ContactDetails from "../ContactDetails/ContactDetails";
import HalfHeightBlock from "../HalfHeightBlock/HalfHeightBlock";
import MiniNavigation from "../MiniNavigation/MiniNavigation";
import PageTransition from "../PageTransition";
// import LogoLists from '../LogoLists/LogoLists';
import BCorpLogo from "../../images/svg/bcorp.svg";
import Logo from "../Logo/Logo";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import Cursor from '../Cursor/Cursor';
import { CursorContextProvider } from "../../utils/context/cursorContext";
import "./Layout.scss";

const Layout = ({ children, location }) => {
  const [contactVisible, setContactVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const slug = location.replace(/\//g, "") || "home";
  let type = "page";

  if (location.includes("/projects/")) {
    type = "project";
  } else if (location.includes("/posts/")) {
    type = "perspective";
  }

  const {
    wp: {
      themeOptions: { themeOptions },
    },
    allWpMenu: { nodes },
  } = useStaticQuery(graphql`
    query ThemeOptionsQuery {
      wp {
        themeOptions {
          themeOptions {
            socialInstagram {
              target
              title
              url
            }
            socialLinkedin {
              url
              title
              target
            }
            phoneNumber {
              url
              title
              target
            }
            location {
              url
              title
              target
            }
            info
            careersEmail {
              url
              title
              target
            }
            enquiriesEmail {
              url
              title
              target
            }
            acknowledgement
          }
        }
      }
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
  `);

  const menuNav = nodes.filter((node) => node.name === "Nav Menu")[0].menuItems
    .nodes;

  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Seesaw Studio</title>
      </Helmet> */}
      <CursorContextProvider>
        {/* <Cursor /> */}
        <Scroll callbacks={window?.location?.pathname} />
        {/* {slug !== "home" && slug !== "studio" ? ( */}
        {/*   <Scroll callbacks={window?.location} /> */}
        {/* ) : ( */}
        {/*   <ScrollSmoother callbacks={window?.location} /> */}
        {/* )} */}

        {/* <PageTransition location={window?.location}> */}
        <main className={`${slug} ${type}`} data-scroll-container>
          <div data-scroll-wrapper>{children}</div>
        </main>
        {/* </PageTransition> */}

        <>
          <PopupBlock
            position="right"
            direction="bottom"
            bgColour="#C17A67"
            linkText="Purpose"
            burger={true}
          >
            <Link
              className="menu-header"
              direction="right"
              cover
              to="/"
              duration={1.5}
              bg="#f2f3f0"
            >
              <h3>Seesaw Studio</h3>
            </Link>
            <HalfHeightBlock className="">
              <div
                className={`flex-wrapper${
                  contactVisible ? " contact-active" : ""
                }`}
              >
                <ul>
                  <li>
                    <Link
                      className="large"
                      cover
                      direction="right"
                      to="/"
                      duration={1.5}
                      bg="#f2f3f0"
                    >
                      Home
                    </Link>
                  </li>
                  {menuNav &&
                    menuNav.map((item, index) => {
                      let { label, url } = item;
                      return (
                        <li key={index}>
                          <Link
                            className="large"
                            direction="right"
                            cover
                            to={url}
                            duration={1.5}
                            bg="#f2f3f0"
                            onClick={() => {
                              setContactVisible(false);
                            }}
                          >
                            {label}
                          </Link>
                        </li>
                      );
                    })}

                  <li>
                    <button
                      className="large"
                      type="button"
                      onClick={() => setContactVisible(!contactVisible)}
                    >
                      Contact
                    </button>
                  </li>
                </ul>
                {!contactVisible ? (
                  <div className="menu-footer">
                    <div className="menu-footer__column">
                      {themeOptions.acknowledgement ? (
                        <span className="small acknowledgement">
                          {themeOptions.acknowledgement}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="menu-footer__column">
                      <span className="small copyright">
                        &copy;{` Seesaw / ${currentYear} All Rights Reserved`}
                      </span>
                      <BCorpLogo />
                    </div>
                  </div>
                ) : (
                  <ContactDetails data={themeOptions} />
                )}
              </div>
            </HalfHeightBlock>
          </PopupBlock>

          <PopupBlock
            position="right"
            linkPosition="right"
            direction="right"
            bgColour="#C17A67"
            linkText="Hello"
            slug={slug}
          >
            <Logo logoText="S" url="/" />
            <ContactDetails data={themeOptions} />
          </PopupBlock>

          <PopupBlock
            position="right"
            linkPosition="left"
            direction="right"
            bgColour="#C17A67"
            linkText="Purpose"
            slug={slug}
            linkUrl="/purpose"
          >
            <Logo logoText="S" url="/" />
            <HalfHeightBlock>
              <MiniNavigation heading="Purpose" position="flex" />
              {/* !TEMP */}
              <div className="flex-wrapper">
                <p
                  className="small"
                  dangerouslySetInnerHTML={{ __html: themeOptions.info }}
                />
                {/* <LogoLists /> */}
              </div>
            </HalfHeightBlock>
          </PopupBlock>
        </>
      </CursorContextProvider>
    </>
  );
};

export default Layout;
