import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from "locomotive-scroll";
// import { TransitionState } from "gatsby-plugin-transition-link"; // Broken..

gsap.registerPlugin(ScrollTrigger);

let currentPosition = 0;
let bodyHeight = 0;

const Scroll = ({ callbacks }) => {
  let locomotiveScroll = null;
  let prevCallbacks = null;

  // this is ridiculous!
  let timeout = null;
  let timeout2 = null;
  let bgTimeout = null;
  let bgTimeout2 = null;
  let bgTimeout3 = null;
  let navTimeout = null;
  let locoTimeout = null;
  let scrollTimeout = null;
  let innerScrollTimeout = null;

  const resize = () => {
    ScrollTrigger.refresh();
    locomotiveScroll.update();
  };

  const initScroll = () => {
    const isMobile = window.innerWidth < 1024;
    const stickyElements = document.querySelectorAll("[data-scroll-sticky]");
    const urlParams = new URLSearchParams(window.location.search);
    const toParam = urlParams.get("to");

    if (isMobile) {
      for (const element of stickyElements) {
        element.removeAttribute("data-scroll-sticky");
        element.removeAttribute("data-scroll-target");
      }
    }

    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      class: "is-inview",
      initPosition: { x: 0, y: currentPosition },
      getDirection: true,
      mobile: {
        breakpoint: 0,
        smoothMobile: true,
        getDirection: true,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
        getDirection: true,
      },
    });

    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll;

    locomotiveScroll.on("scroll", (func) => {
      ScrollTrigger.update();
      let limit = func.limit.y;

      if (window.innerWidth < 1024) {
        limit = document.body.scrollHeight - window.innerHeight;
      }

      const scrollY =
        window.innerWidth >= 1024 ? func.scroll.y : window.scrollY;
      const progress = scrollY / limit;

      if (progress >= 0.001 && progress < 1) {
        document.body.classList.add("scrolled");
      } else {
        document.body.classList.remove("scrolled");
      }

      if (
        (scrollY > window.innerHeight &&
          document.querySelector(".is-front-page")) ||
        (document.querySelector(".brand-digital-projects") &&
          scrollY > window.innerHeight) ||
        (document.querySelector("main.studio") && scrollY > window.innerHeight)
      ) {
        document.body.classList.add("threshold");
      } else {
        document.body.classList.remove("threshold");
      }

      if (progress >= 0.001) {
        document.body.classList.add("interacted");
      } else {
        document.body.classList.remove("interacted");
      }

      document.documentElement.setAttribute("data-direction", func.direction);

      const section = Object.keys(func.currentElements).find((key) => {
        return func.currentElements[key]?.section?.el?.classList.contains(
          "section"
        );
      });

      const lastSection = Object.keys(func.currentElements)
        .reverse()
        .find((key) => {
          return (
            func.currentElements[key]?.section?.el.classList.contains(
              "footer"
            ) ||
            func.currentElements[key]?.section?.el.classList.contains(
              "next_page"
            ) ||
            func.currentElements[key]?.section?.el.classList.contains(
              "page-footer"
            )
          );
        });

      // BAD PERFORMANCE
      const sections = document.querySelectorAll(
        ".tl-wrapper-status--entered [data-bg]"
      );

      for (const section of sections) {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionHeight = section.getBoundingClientRect().height;
        const bg = section.getAttribute("data-bg") || "#f2f3f0";

        if (
          (progress >= 0.95 && section.classList.contains("page-footer")) ||
          (progress >= 0.95 && section.classList.contains("next-post"))
        ) {
          document.body.style.setProperty("background-color", bg);
        } else if (
          scrollY >= sectionTop &&
          scrollY <= sectionTop + sectionHeight + scrollY &&
          !section.classList.contains("page-footer") &&
          !section.classList.contains("next-post")
        ) {
          document.body.style.setProperty("background-color", bg);
        }
      }

      currentPosition = func.scroll.y;
      bodyHeight = document.body.scrollHeight;
    });

    resize();

    window.addEventListener("resize", () => resize);

    timeout = setTimeout(() => {
      document.body.classList.add("init");

      if (!isMobile) {
        resize();
      }
    }, 1000);

    timeout2 = setTimeout(() => {
      if (!isMobile) {
        resize();
      }
    }, 1900);

    ScrollTrigger.scrollerProxy("[data-scroll-container]", {
      scrollTop(value) {
        return arguments.length
          ? locomotiveScroll.scrollTo(value, 0, 0)
          : locomotiveScroll.scroll.instance.scroll.y;
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector("[data-scroll-container]").style.transform
        ? "transform"
        : "fixed",
    });

    ScrollTrigger.defaults({
      scroller: "[data-scroll-container]",
    });

    ScrollTrigger.config({ ignoreMobileResize: true });

    ScrollTrigger.addEventListener("refresh", () => resize);
    document.addEventListener("lazyloaded", () => resize);
    window.dispatchEvent(new Event("resize"));
    ScrollTrigger.refresh();

    navTimeout = setTimeout(() => {
      if (
        document.querySelector(".is-front-page") ||
        document.querySelector(".brand-digital-projects") ||
        document.querySelector("main.studio") ||
        document.querySelector("main.project")
      ) {
        document.querySelector(".burger")?.classList.add("white");
        document.querySelector("nav.nav")?.classList.add("white");
      } else {
        document.querySelector(".burger")?.classList.remove("white");
        document.querySelector("nav.nav")?.classList.remove("white");
      }
    }, 700);

    scrollTimeout = setTimeout(() => {
      locomotiveScroll.scrollTo(0, {
        duration: 0,
        easing: [0, 0, 0, 0],
        disableLerp: true,
        callback: () => {
          document.body.style.removeProperty("background-color");
        },
      });
      if (toParam) {
        innerScrollTimeout = setTimeout(
          () => {
            window.scroll.scrollTo(`#${toParam}`, {
              duration: 0,
              disableLerp: true,
            });
          },
          window.innerWidth < 1024 ? 1200 : 700
        );
      }
    }, 701);
  };

  useEffect(() => {
    if (prevCallbacks === callbacks) return;

    initScroll();

    return () => {
      window.scroll?.destroy();
      document.body.classList.remove("scrolled");
      document.removeEventListener("resize", resize);
      ScrollTrigger.removeEventListener("refresh", resize);
      document.removeEventListener("lazyloaded", resize);
      document.body.style.removeProperty("background-color");

      clearTimeout(timeout);
      clearTimeout(timeout2);
      clearTimeout(locoTimeout);
      clearTimeout(navTimeout);
      clearTimeout(scrollTimeout);
      clearTimeout(innerScrollTimeout);
    };
  }, [callbacks]);

  return false;
};

export default Scroll;
