import React from "react";
import "./HalfHeightBlock.scss";

// ! Hmmm could be moved into a new folder called 'layout'?
const HalfHeightBlock = ({ children, className }) => {
  return (
    <div className={`half_height_block ${className}`}>
      <div className="half_height_block__wrapper">{children}</div>
    </div>
  );
};

export default HalfHeightBlock;
