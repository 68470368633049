import React from "react";
import MiniNavigation from "../MiniNavigation/MiniNavigation";
import BCorpLogo from "../../images/svg/bcorp.svg";
import "./ContactDetails.scss";
import "./ContactLink/ContactLink.scss"; // ! TEMP

// ! Messy - Needs cleaning up.
const ContactDetails = ({
  data: {
    socialInstagram,
    socialLinkedin,
    phoneNumber,
    location,
    enquiriesEmail,
    careersEmail,
  },
  miniNav = true,
}) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="contact_details">
      <div className="contact_details__content">
        {/* TODO: convert these into a reusable component later*/}

        <div className="contact_details__row">
          <div className="contact_details__column">
            {location.title && (
              <div className="contact_link contact_details__contact">
                <span>Visit</span>
                <a
                  className="large"
                  href={location.url}
                  target={location.target}
                  rel="noopener noreferrer"
                >
                  20 Vale St North Melbourne
                  <br />
                  Victoria 3051. Australia
                </a>
              </div>
            )}

            {/* <div className="contact_link contact_details__contact contact_link--phone mobile"> */}
            {/*   <span>Chat</span> */}
            {/*   {phoneNumber?.title && phoneNumber?.url && ( */}
            {/*     <a className="large" href={phoneNumber.url}> */}
            {/*       {phoneNumber.title} */}
            {/*     </a> */}
            {/*   )} */}
            {/*   {enquiriesEmail?.title && enquiriesEmail?.url && ( */}
            {/*     <a className="large" href={enquiriesEmail.url}> */}
            {/*       {enquiriesEmail.title} */}
            {/*     </a> */}
            {/*   )} */}
            {/* </div> */}

            <div className="contact_link contact_details__contact contact_link--phone">
              <span>Call</span>
              {phoneNumber?.title && phoneNumber?.url && (
                <a className="large" href={phoneNumber.url}>
                  {phoneNumber.title}
                </a>
              )}
            </div>

            <div className="contact_link contact_details__contact contact_link--social">
              <span>Follow</span>
              {socialInstagram?.title && socialInstagram?.url && (
                <a
                  className="large"
                  href={socialInstagram.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {socialInstagram.title}
                </a>
              )}
              {socialLinkedin?.title && socialLinkedin?.url && (
                <a
                  className="large"
                  href={socialLinkedin.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {socialLinkedin.title}
                </a>
              )}
            </div>

            <div className="contact_link contact_details__contact contact_link--contact">
              <span>Email</span>
              {enquiriesEmail?.title && enquiriesEmail?.url && (
                <a className="large" href={enquiriesEmail.url}>
                  Enquiries
                </a>
              )}
              {careersEmail?.title && careersEmail?.url && (
                <a className="large" href={careersEmail.url}>
                  Careers
                </a>
              )}
            </div>
            <BCorpLogo />
          </div>
        </div>

        {/* <span className="contact_details__copyright small"> */}
        {/*   &copy;{` Seesaw / ${currentYear} All Rights Reserved`} */}
        {/* </span> */}
      </div>
    </div>
  );
};

export default ContactDetails;
