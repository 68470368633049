import React from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

const timeout = 1000;

const PageTransition = ({ children, location }) => (
  <TransitionGroup className="transition-group">
    <ReactTransition
      key={location}
      timeout={timeout}
      onExit={(node) => {
        // window.scroll.stop();
      }}
      onEnter={(node) => {
        setTimeout(() => {
          if (window.innerWidth >= 1024) {
            // window.scroll.start();
            ScrollTrigger.refresh();
            window.dispatchEvent(new Event("resize"));
          }
        }, timeout * 2);
      }}
      onEntered={(node) => {}}
    >
      {(status) => <div className={`woosh ${status}`}>{children}</div>}
    </ReactTransition>
  </TransitionGroup>
);

export default PageTransition;
